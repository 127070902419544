define("discourse/plugins/discourse-subscriptions/discourse/models/user-payment", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const UserPayment = _object.default.extend((_dec = (0, _decorators.default)("amount"), (_obj = {
    amountDollars(amount) {
      return parseFloat(amount / 100).toFixed(2);
    }
  }, (_applyDecoratedDescriptor(_obj, "amountDollars", [_dec], Object.getOwnPropertyDescriptor(_obj, "amountDollars"), _obj)), _obj)));
  UserPayment.reopenClass({
    findAll() {
      return (0, _ajax.ajax)("/s/user/payments", {
        method: "get"
      }).then(result => result.map(payment => {
        return UserPayment.create(payment);
      }));
    }
  });
  var _default = _exports.default = UserPayment;
});