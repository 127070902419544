define("discourse/plugins/discourse-subscriptions/discourse/models/admin-plan", ["exports", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/plugins/discourse-subscriptions/discourse/models/plan"], function (_exports, _ajax, _decorators, _plan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const AdminPlan = _plan.default.extend((_dec = (0, _decorators.default)("trial_period_days"), (_obj = {
    isNew: false,
    name: "",
    interval: "month",
    unit_amount: 0,
    intervals: ["day", "week", "month", "year"],
    metadata: {},
    parseTrialPeriodDays(trialDays) {
      if (trialDays) {
        return parseInt(0 + trialDays, 10);
      } else {
        return 0;
      }
    },
    save() {
      const data = {
        nickname: this.nickname,
        interval: this.interval,
        amount: this.unit_amount,
        currency: this.currency,
        trial_period_days: this.parseTrialPeriodDays,
        type: this.type,
        product: this.product,
        metadata: this.metadata,
        active: this.active
      };
      return (0, _ajax.ajax)("/s/admin/plans", {
        method: "post",
        data
      });
    },
    update() {
      const data = {
        nickname: this.nickname,
        trial_period_days: this.parseTrialPeriodDays,
        metadata: this.metadata,
        active: this.active
      };
      return (0, _ajax.ajax)(`/s/admin/plans/${this.id}`, {
        method: "patch",
        data
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "parseTrialPeriodDays", [_dec], Object.getOwnPropertyDescriptor(_obj, "parseTrialPeriodDays"), _obj)), _obj)));
  AdminPlan.reopenClass({
    findAll(data) {
      return (0, _ajax.ajax)("/s/admin/plans", {
        method: "get",
        data
      }).then(result => result.map(plan => AdminPlan.create(plan)));
    },
    find(id) {
      return (0, _ajax.ajax)(`/s/admin/plans/${id}`, {
        method: "get"
      }).then(plan => AdminPlan.create(plan));
    }
  });
  var _default = _exports.default = AdminPlan;
});