define("discourse/plugins/discourse-subscriptions/discourse/models/admin-coupon", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const AdminCoupon = _object.default.extend((_dec = (0, _decorators.default)("coupon.amount_off", "coupon.percent_off"), (_obj = {
    discount(amount_off, percent_off) {
      if (amount_off) {
        return `${parseFloat(amount_off * 0.01).toFixed(2)}`;
      } else if (percent_off) {
        return `${percent_off}%`;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "discount", [_dec], Object.getOwnPropertyDescriptor(_obj, "discount"), _obj)), _obj)));
  AdminCoupon.reopenClass({
    list() {
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "get"
      }).then(result => {
        if (result === null) {
          return {
            unconfigured: true
          };
        }
        return result.map(coupon => AdminCoupon.create(coupon));
      });
    },
    save(params) {
      const data = {
        promo: params.promo,
        discount_type: params.discount_type,
        discount: params.discount,
        active: params.active
      };
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "post",
        data
      }).then(coupon => AdminCoupon.create(coupon));
    },
    update(params) {
      const data = {
        id: params.id,
        active: params.active
      };
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "put",
        data
      }).then(coupon => AdminCoupon.create(coupon));
    },
    destroy(params) {
      const data = {
        coupon_id: params.coupon.id
      };
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "delete",
        data
      });
    }
  });
  var _default = _exports.default = AdminCoupon;
});