define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-subscriptions", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-subscriptions/discourse/components/modal/admin-cancel-subscription", "discourse/plugins/discourse-subscriptions/discourse/models/admin-subscription"], function (_exports, _controller, _object, _service, _discourseI18n, _adminCancelSubscription, _adminSubscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    modal: (0, _service.inject)(),
    dialog: (0, _service.inject)(),
    loading: false,
    showCancelModal(subscription) {
      this.modal.show(_adminCancelSubscription.default, {
        model: {
          subscription,
          cancelSubscription: this.cancelSubscription
        }
      });
    },
    loadMore() {
      if (!this.loading && this.model.has_more) {
        this.set("loading", true);
        return _adminSubscription.default.loadMore(this.model.last_record).then(result => {
          const updated = this.model.data.concat(result.data);
          this.set("model", result);
          this.set("model.data", updated);
          this.set("loading", false);
        });
      }
    },
    cancelSubscription(model) {
      const subscription = model.subscription;
      const refund = model.refund;
      subscription.set("loading", true);
      subscription.destroy(refund).then(result => {
        subscription.set("status", result.status);
        this.dialog.alert(_discourseI18n.default.t("discourse_subscriptions.admin.canceled"));
      }).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n"))).finally(() => {
        subscription.set("loading", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "showCancelModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "showCancelModal"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "cancelSubscription", [_object.action], Object.getOwnPropertyDescriptor(_obj, "cancelSubscription"), _obj)), _obj));
});