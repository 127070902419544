define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions", ["exports", "@ember/object", "@ember/routing/route", "@ember/service"], function (_exports, _object, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _route.default.extend((_obj = {
    router: (0, _service.inject)(),
    showSettings() {
      const controller = this.controllerFor("adminSiteSettings");
      this.router.transitionTo("adminSiteSettingsCategory", "plugins").then(() => {
        controller.set("filter", "plugin:discourse-subscriptions campaign");
        controller.set("_skipBounce", true);
        controller.filterContentNow("plugins");
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "showSettings", [_object.action], Object.getOwnPropertyDescriptor(_obj, "showSettings"), _obj)), _obj));
});